.MuiBadge-dot {
    padding: 0;
    margin-right: 4px;
    margin-top: 4px;
    z-index: auto;
    min-width: 10px;
    width: 10px !important;
    height: 10px !important;
    opacity: 0.99;
    background: #ff4181;
    border-radius: 100% !important;
}

.MuiDataGrid-root {
    padding: 0;
}

/* Don't put damn blue outline around focus. */
.MuiDataGrid-columnHeader:focus,
.MuiDataGrid-cell:focus-within,
.MuiDataGrid-cell:focus,
.MuiDataGrid-columnHeader:focus-within {
    outline: none !important;
    outline-width: 0 !important;
}

.MuiDataGrid-columnHeader:focus,
.MuiDataGrid-cell:focus {
    outline: none !important;
}

/* No un-sort icon. */
.MuiDataGrid-root
.MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted)
.MuiDataGrid-sortIcon,
.MuiDataGrid-root
.MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted):hover
.MuiDataGrid-iconButtonContainer {
    opacity: 0;
}

/* Sort icons. */
.MuiDataGrid-root .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer .MuiDataGrid-sortIcon {
    width: 1.75em;
    height: 1.5em;
    outline: none !important;
    fill: rgb(0, 0, 0, 0.47);
    background-color: transparent;
}

/* Header settings. */
.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle {
    color: rgba(0, 0, 0, 0.77);
    font-weight: 500 !important;
    padding-right: 8px !important;
    /* max-height: 40px !important; */
    min-height: 40px !important;
    height: 40px;
    line-height: 42px;
    font-family: 'Roboto', 'Arial', serif !important;
}

.MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer {
    padding-left: 8px !important;
}

/* Dont show the sort menu. It's stupid. */
.MuiDataGrid-root .MuiDataGrid-menuIcon {
    display: none !important;
}

/* Show 3 dots when text is long. */
.MuiDataGrid-cell {
    display: inline-block !important;
    text-overflow: ellipsis !important;
    margin: auto !important;
    padding-bottom: 9px !important;
    min-height: 40px !important;
    max-height: 40px !important;
    font-size: 14px !important;
    color: rgba(0, 0, 0, 0.87) !important;
}

/* More header settings. */
.MuiDataGrid-columnHeaders {
    max-height: 40px !important;
    min-height: 40px !important;
    height: 40px;
    line-height: 42px !important;
    padding: 0 !important;
    margin: 0;
    background-color: #f5f5f5;
    font-size: 16px;
    text-align: left;
    width: 100%;
    color: rgb(68, 68, 68);
}

.MuiDataGrid-columnHeader {
    font-size: 16px !important;
    height: 40px;
    line-height: 40px;
    color: #000000c4 !important;
    font-weight: 500 !important;
    border-bottom: 0 !important;
    padding: 0 !important;
    outline: none !important;
    border-right: 1px solid rgb(224, 224, 224);
}

.MuiDataGrid-columnHeader:not(:last-of-type) {
    border-right: 1px solid rgb(219, 219, 219);
}

.MuiDataGrid-columnsContainer {
    background: #f5f5f5 !important;
}

.MuiDataGrid-iconSeparator {
    color: transparent !important;
}

.MuiPaper-root {
    border-bottom: 1px solid rgb(224, 224, 224);
}

#filterApplyButton:disabled,
#filterClearButton:disabled {
    color: rgba(255, 255, 255, 0.5) !important;
}

/* Dont highlight buttons on hover. It's stupid. */
#filterApplyButton:hover,
#filterClearButton:hover {
    background-color: transparent;
}

/* Dont highlight checkbox on hover. It's stupid. */
.MuiCheckbox-root:hover {
    background-color: transparent !important;
}

/* Dont highlight sort icons on hover. It's stupid. */
.MuiIconButton-sizeSmall:hover {
    background-color: transparent !important;
}

.MuiTab-root {
    texttransform: 'none' !important;
}

.MuiTab-textColorPrimary {
    color: #cccccc;
}

button.Mui-selected {
    color: white !important;
}

.MuiTab-fullWidth {
    background: #4c7bfc !important;
    color: rgba(255, 255, 255, 0.8);
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-bottom: 0 !important;
    border-left: 1px solid #fff !important;
    border-right: 1px solid #fff !important;
    margin-top: -8px;
}

.MuiTabs-scroller {
    background: white !important;
}

.MuiTab-root {
    padding-top: 0 !important;
    font-size: 14px !important;
    font-weight: 700 !important;
}

div#bottombar .header-container {
    height: 68px !important;
}

.MuiDataGrid-columnHeader:focus {
    outline: none !important;
}

.stickyTop, .header-container {
    position: sticky;
    top: 0;
    z-index: 999;
}
