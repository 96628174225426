@import "https://js.arcgis.com/4.28/@arcgis/core/assets/esri/themes/light/main.css";
#root {
  height: 100vh;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiDropzoneArea-icon {
  color: #2961FD !important;
}

/*CKEditor used in edit homepage text*/
.ck-editor__editable {
  min-height: 417px;
}

.MuiSpeedDial-fab {
  background-color: #ff4081;
  color: white !important;
}

#mapId {
  height: calc(100vh - 56px);
  width:100vw;
}
div[role='tooltip'] {
  border: none !important;
}

.react-editor-mitigation {
  height: 50%;
}

.custom-popup {
  width: 100%;
}

.MuiDataGrid-root .MuiDataGrid-row  {
  min-height: 52px !important;
}

.MuiDataGrid-virtualScroller {
  overflow: unset !important;
}

.esri-popup__main-container {
display: block;
}

.esri-popup__header {
display: flex;
justify-content: space-between;
align-items: center;
  background-color: #2961FD;
    padding: 8px;
}

.esri-popup__header-container--button {
  background-color: transparent;
  border: none;
}

.esri-popup__header-buttons {
  padding: 8px
}

.esri-ui .esri-popup .esri-widget__heading {
color: white;
}

.esri-popup__action-text, .esri-popup__button--dock, .esri-popup__collapse-icon, .esri-popup__feature-menu {
  display:none;
}

.esri-popup__inline-actions-container .esri-popup__button .esri-popup__icon {
  display: none;
}

.esri-popup__icon {
  color: white;
  &:hover {
    cursor: pointer;
  }
}

.esri-feature__content-element {
  padding: 0;
  margin: 0;
}

.popupHeaderText, .popupSubheaderText  {
  color: white;
}
.popupSubheaderText {
  font-weight: 400;
    font-size: 14px;
}

.esri-popup__content {
  padding: 8px;
}

.esri-widget__table tr th {
  font-weight: 600;
    font-size: 14px;
}

.esri-widget__table tr td {
  font-weight: 400;
    font-size: 14px;
}

.esri-ui-corner .esri-component.esri-widget--panel {
  width: 300px;
}

.esri-view-height-greater-than-medium .esri-ui-corner .esri-component.esri-widget--panel {
  max-height: 695px;
}

.esri-legend__layer-body, .esri-legend__layer  {
  margin-left: 0;
}

.esri-legend__layer-cell {
  padding: 2px;
}

.esri-legend__layer {
  font-size: 14px
}

.esri-legend__layer-child-table .esri-legend__layer-caption {
  display: none;
}